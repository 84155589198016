<template>
  <div class="content-confirm">
    <AppHeader title="上机确认"/>
    <div class="booking-inf" v-if="booking">
      <div class="eq-title">{{ booking.equipment.name }}</div>
      <div class="eq-img" v-if="booking.equipment.avatar"><img :src="`${this.imgUrl}/${booking.equipment.avatar}`" class="eq-img" /></div>
      <div class="eq-img" v-else>没有图片</div>
      <div class="booking-desc">
        <div class="booking-desc-line"><span>存放地点:</span>{{ booking.equipment.eqLocation }}</div>
        <div class="booking-desc-line"><span>预约时间: </span>{{ beginEnd(booking.beginAt, booking.endAt)}}</div>
        <div class="booking-desc-line"><span>当前时间: </span>{{ this.$moment().format('YYYY.MM.DD HH:mm') }}</div>
        <div class="booking-desc-line"><span>当前状态: </span><span class="eq-status">待机中</span></div>
      </div>
    </div>
    <div class="content">
      <div class="btn green"  @click="registBiz()" v-if="blueToothTypeShow ">蓝牙上机</div>
      <div class="btn green" @click="startExp" v-if="!blueToothTypeShow">确认上机</div>
    </div>

    <div class="overlay" v-if="loadFlag">
      <div class="tooltip"> 数据加载中...</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色 */
  z-index: 10; /* 确保遮罩层在内容上方 */
  display: flex; /* 使用 Flexbox */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */

}
.tooltip {
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* 阴影 */
}

.eq-status {
  font-size: 15px;
  font-weight: bold;
  color: #F86719 !important;
}

.booking-desc {
  margin-top: 20px;
  width: 100%;
}

.booking-inf {
  padding: 15px 20px;
  background: #fff;
  border-radius: 5px;
  margin: 50px 20px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.content-confirm {
  margin: 0;
  background: linear-gradient(#31B8D3, #1682A7);
}

.eq-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333333;
}

.eq-img {
  width: 240px;
  height: 240px;
  line-height: 240px;
  align-self: center;
  border-radius: 5px;
  border: 1px solid #E6E6E6;
  background: RGBA(67, 74, 84, .9);
  color: #fff;
  text-align: center;
  font-size: 16px;
}

.booking-desc-line {
  font-size: 15px;
  color: #666;
  display: flex;
  align-items: center;
  margin-top: 10px;
  span {
    font-weight: bold;
    color: #333;
    padding-right: 5px;
  }

  &:first-child{
    margin-top: 0;
  }
}
</style>
<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {MessageBox, Toast,Indicator} from 'mint-ui';
import store from "@/plugins/store";

export default {
  components: {
    AppHeader,
    Panel
  },
  data() {
    return {
      booking: null,
      equipment: null,
      blueToothTypeShow:false,
      endFlag: false,
      loadFlag: false
    }
  },
  methods: {
    beginEnd(beginAt, endAt) {
      let beginYMD = this.$moment(beginAt).format('YYYY.MM.DD')
      let result = this.$moment(beginAt).format('YYYY.MM.DD HH:mm')
      if (endAt) {
        let endYMD = this.$moment(endAt).format('YYYY.MM.DD')
        if (endYMD == beginYMD) {
          result += ("-" + this.$moment(endAt).format('HH:mm'))
        } else {
          result += ("-" + this.$moment(endAt).format('YYYY.MM.DD HH:mm'))
        }
      } else {
        result += "-"
      }
      return result
    },
    startExp(){
      const that = this
      var eqId=that.$route.query.equipmentId
      var isnoBooking= that.$route.query.isNoBooking
      console.log("isnoBooking",isnoBooking)
      var url='/api/booking/start?id='+this.booking.id+'&withoutHeartbeat=true'
      if(isnoBooking && isnoBooking==='true'){
        console.log("startnobooking")
        url='/api/booking/start?code='+this.booking.equipment.code+'&withoutHeartbeat=true'
      }

      this.$http.post(url).then(res=>{
        if(res.success){
          that.$router.push({
            path: '/exp',
            query: {
              equipmentId: eqId,
              bookingId: res.data.bookingId
            }
          })
        }else {
          Toast(res.msg)
        }
        // that.$router.push("/exp")
      })
    },
    registBiz(){
      var that=this
      var eqId=that.$route.query.equipmentId
      const fullPath=encodeURIComponent(that.$route.fullPath)
      this.$http.get('/api/booking/regisBiz?equipmentId='+eqId+"&bizOperator=open&bookingId="+this.booking.id+"&fullPath="+fullPath).then(res =>{
        if (res.success && res.data && res.data.scheme){
          that.scheme=res.data.scheme
          let bizId=res.data.bizId
          that.getBizStatus(bizId);
          window.location.href= that.scheme
          let count=0;
          let timer=setInterval(()=>{
            that.getBizLastStatus(bizId);
            count++;
            this.loadFlag=true
            if (that.endFlag || count>10){
              clearInterval(timer)
              this.loadFlag=false
              if (count>10){
                setTimeout(()=>{
                  Toast('抱歉，蓝牙连接失败，请检查微信位置、微信附近的设备和蓝牙是否开启。确认开启后，请重新扫码或尝试其他设备');
                },1000)
              }
            }
          }, 3000);
        }
      })

    },
    getBizLastStatus(bizId){
      if (!this.endFlag){
        this.$http.get('/api/booking/getBizLastStatus?bizId='+bizId+'&bizOperator=open').then(response =>{
          if(response.success && response.data.machineLastState=='1'){
            this.endFlag=true
            this.startExp()
          }
        })
      }

    },
    getBizStatus(bizId){
      var isnoBooking= this.$route.query.isNoBooking
      var urls='/api/booking/getBizStatus?bizId='+bizId+"&bizOperator=open&bookingId="+this.booking.id
      if(isnoBooking && isnoBooking==='true'){
        var urls='/api/booking/getBizStatus?bizId='+bizId+"&bizOperator=open&code="+this.booking.equipment.code
      }
      this.$http.post(urls).then(res =>{})
    },
  },
  mounted() {
    // var isNoBooking= this.$route.query.isNoBooking
    // if(isNoBooking){
    //   this.equipment = this.$route.query.equipment
    //   console.log("equipment",this.equipment)
    // }else {
      this.booking = store.getters.curBooking
      console.log("this.booking",this.booking)
    // }

    if (this.booking.equipment.blueToothId){
      this.blueToothTypeShow=true
    }

  }
}
</script>
